<template>
  <v-alert
    v-if="alert"
    v-model="alert"
    class="cpd-banner"
    border="left"
    :color="color"
    dark
    dense
    dismissible
  >
    <b>New Pathfinder Version 3.0 to launch October 1st, 2024!</b>
  </v-alert>
</template>

<script>
export default {
  name: 'FixedBanner',
  props: {
    color: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return ({
      alert: null
    })
  },
  watch: {
    alert (value) {
      this.$cookies.set(`show-banner-${this.name}`, value)
    }
  },
  created () {
    const currentBannerState = this.$cookies.get(`show-banner-${this.name}`)
    this.alert = currentBannerState !== 'false'
  }
}
</script>

<style>
.cpd-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  width: 100%;
  border-radius: 0 !important;
  text-align: center;
}
div:has(> .cpd-banner) {
  padding-bottom: 100px !important;
}
</style>
