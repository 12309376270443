<template>
  <v-progress-circular
    ref="progress"
    :rotate="-90"
    :size="actualSize"
    :value="progressPercentage"
    :width="actualSize * 0.12"
    :color="color"
    style="border-radius: 50%;"
    :style="{ 'background-color': fill }"
  >
    <div
      class="d-flex flex-column align-center text-center"
      :style="{ 'padding-top': actualSize * 0.05 + 'px' }"
    >
      <span
        v-if="pending || yearsToPositive === undefined || yearsToPositive === null"
        style="line-height: 0.5;"
        :style="{'font-size': actualSize*0.31 + 'px'}"
      >
        -
      </span>
      <span
        v-else
        style="line-height: 0.5;"
        :style="{'font-size': actualSize*0.31 + 'px'}"
      >
        {{ yearsToPositive }}
      </span>
      <span
        v-if="textInside"
        style="line-height: 1.15;"
        :style="{ 'font-size': actualSize*0.11 + 'px', 'margin-top': `${actualSize * 0.07}px` }"
      >
        <span>year{{ yearsToPositive !== 1 ? 's' : '' }} to<br> positive</span>
      </span>
    </div>
  </v-progress-circular>
</template>

<script>
import windowScale from '@/mixins/windowScale'
import { mapState, mapGetters as mapGlobalGetters } from 'vuex'
import * as d3 from 'd3'

export default {
  name: 'YearsToPositiveDonut',
  mixins: [windowScale],
  props: {
    size: {
      type: Number,
      default: 130
    },
    scaleWithWindow: {
      type: Boolean,
      default: true
    },
    textInside: {
      type: Boolean,
      default: false
    },
    fill: {
      type: String,
      default: 'none'
    },
    circleFill: {
      type: String,
      default: null
    },
    yearsToPositive: {
      type: Number,
      default: null
    },
    projectType: {
      type: String,
      required: true
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['colors']),
    ...mapGlobalGetters(['projectTypes']),

    targetYtp () {
      const { targetYears } = this.projectTypes.find(x => x.name === this.projectType)
      return targetYears
    },
    progressPercentage () {
      const value = Math.max(0, 100 - (this.yearsToPositive / (this.targetYtp / 100)))
      return value
    },
    color () {
      if (this.yearsToPositive === undefined || this.yearsToPositive === null) {
        return this.colors.metal
      }
      const scale = d3.scaleLinear()
        .range([this.colors.shamrock, this.colors.metal])
        .domain([0, this.targetYtp + 5])
        .clamp(true) // clamp so that the scale will never go outside the color range specified
      return scale(this.yearsToPositive)
    },
    actualSize () {
      // const max = this.size
      return this.scaleWithWindow ? this.windowScale(this.size * 0.65, this.size) : this.size
    }
  },
  mounted () {
    if (this.circleFill) {
      this.$refs.progress.$el.querySelector('.v-progress-circular__underlay').style.stroke = this.circleFill
    }
  }
}
</script>
