<template>
  <div
    v-scroll="updateDonutSize"
    v-resize="updateDonutSize"
    class="project-map-container"
  >
    <div
      ref="map"
      class="map"
    />
    <!-- <div
      v-if="$vuetify.breakpoint.lgAndUp"
      class="carousel-container"
    >
      <ProjectsMapCarousel
        :projects="projects"
        @project-focused="handleProjectFocused"
      />
      <v-btn
        color="white shamrock--text"
        to="/projects/new"
        style="left: 50%; width: 200px; transform: translateX(-50%); font-size: 16px;"
        rounded
      >
        New project
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
        v-if="$store.state.auth.user && $store.state.auth.user.isAdmin"
        color="shamrock white--text"
        to="/admin"
        style="left: 50%; width: 200px; transform: translateX(-50%); font-size: 16px;"
        rounded
        class="ml-3"
      >
        Admin zone
      </v-btn>
    </div> -->
    <div
      class="user-ytp-container small"
      :class="{ center: centerDonut, small: $vuetify.breakpoint.smAndDown }"
      :style="{ transform: centerDonut ? `translateX(-50%) translateY(${(donutSize - 90) * 0.15}px)` : null }"
    >
      <YearsToPositiveDonut
        v-show="showDonut && centerDonut"
        :years-to-positive="averageYtp"
        circle-fill="var(--v-metal-base)"
        project-type="Park"
        :size="centerDonut ? donutSize : 50"
        :scale-with-window="false"
      />
      <div
        v-show="centerDonut"
        class="page-header display-1 leaf--text font-weight-light"
        :style="{
          transform: `translateX(-50%) translateY(${donutSize - Math.pow(donutSize, 2) * 0.0005 + 90}px)`,
          opacity: headerOpacity
        }"
      >
        My Projects
      </div>
    </div>
    <a
      class="map-scroll-prompt"
      href="#list"
      style="text-decoration: none;"
    >
      <v-btn
        icon
        color="leaf"
        x-large
      >
        <v-icon x-large>
          mdi-arrow-down
        </v-icon>
      </v-btn>
    </a>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl'
// import ProjectsMapCarousel from './projectsMapCarousel/ProjectsMapCarousel'
import YearsToPositiveDonut from '@/components/atoms/YearsToPositiveDonut'
import * as d3 from 'd3'

export default {
  name: 'ProjectsMap',
  components: {
    // ProjectsMapCarousel,
    YearsToPositiveDonut
  },
  props: {
    projects: {
      type: Array,
      default: () => []
    },
    averageYtp: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoiZWRhbndlaXMiLCJhIjoiY2lmMTVtdWQ0MDRsOHNkbTV2OXd3cDNwNiJ9.MxWj73wGNEvrPSjsh6TJjw',
      locationMap: null,
      markers: [],
      donutSize: 0,
      centerDonut: true,
      headerOpacity: 1,
      showDonut: false
    }
  },
  computed: {
    smallDonutSize () {
      return this.$vuetify.breakpoint.mdAndUp ? 60 : 50
    }
  },
  watch: {
    projects: {
      handler (projects) {
        if (projects) {
          this.loadMap()
          this.$nextTick(() => {
            this.loadMarkers()
          })
        }
      }
    }
  },
  mounted () {
    this.updateDonutSize()
    this.$nextTick(() => {
      this.showDonut = true
    })
  },
  methods: {
    updateDonutSize () {
      const snapPoint = window.innerHeight * 0.5
      this.centerDonut = window.scrollY < snapPoint
      if (this.centerDonut) {
        const fullSize = Math.max(this.$vuetify.breakpoint.width * 0.15, 200)
        const smallestSize = 90
        const scale = d3.scalePow([0, snapPoint], [fullSize, smallestSize]).exponent(7).clamp(true)
        this.donutSize = scale(window.scrollY)
        this.headerOpacity = d3.scalePow([100, snapPoint], [1, 0]).exponent(4)(window.scrollY)
      }
    },
    loadMap () {
      let lngLat
      if (this.projects[0]) {
        lngLat = [...this.projects[0].location.centroid.geometry.coordinates]
        if (!this.projects[0].isLegacy) {
          lngLat.reverse()
        }
      }
      const map = new Mapbox.Map({
        container: this.$refs.map,
        style: 'mapbox://styles/edanweis/ck21qftxa0mr81dk9h5bf4766',
        accessToken: this.accessToken,
        center: lngLat || [0, 0],
        zoom: 9,
        attributionControl: false
      })
      map.on('load', () => {
        map.flyTo({
          center: [0, 0],
          zoom: 1
        })
      })
      this.locationMap = map
      setTimeout(() => {
        if (map.style.stylesheet) {
          map.style.stylesheet.layers.forEach((layer) => {
            if (layer.type === 'symbol') {
              map.removeLayer(layer.id)
            }
          })
        }
      }, 500)
      if (this.projects) {
        this.$nextTick(() => {
          this.loadMarkers()
        })
      }
    },
    loadMarkers (focusedProjectId) {
      for (const marker of this.markers) {
        marker.remove()
      }
      this.markers = []
      for (const project of this.projects) {
        if (!project.location.centroid) {
          continue
        }
        let lngLat = [...project.location.centroid.geometry.coordinates]
        if (!project.isLegacy) {
          lngLat.reverse()
        }
        if (Math.abs(lngLat[1]) > 90) {
          continue
        }
        var el = document.createElement('div')
        el.className = focusedProjectId === project._id ? 'project-map-marker focused' : 'project-map-marker'
        const marker = new Mapbox.Marker(el)
          .setLngLat(lngLat)
          .addTo(this.locationMap)
        this.markers.push(marker)
      }
    },
    handleProjectFocused (project) {
      // this.loadMarkers(project._id)
      // if (!project.location.centroid) {
      //   return
      // }
      if (!project.location.centroid) {
        return
      }
      const lngLat = project.location.centroid.geometry.coordinates
      try {
        this.locationMap.flyTo({
          center: lngLat,
          zoom: 9
        })
      } catch (err) {
        this.locationMap.flyTo({
          center: [0, 0],
          zoom: 1
        })
      }
    }
  }
}
</script>

<style lang="scss">
.project-map-container {
  height: 100vh;
  position: relative;
  .map {
    height: 100%;
    pointer-events: none;
    filter: hue-rotate(178deg) saturate(0) brightness(.54);
  }
  .carousel-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}
.project-map-marker {
  @keyframes pulseMarker {
    0% {
      opacity: 0.8;
      width: 5px;
      height: 5px;
    }
    85% {
      opacity: 0;
      width: 20px;
      height: 20px;
    }
    100% {
      opacity: 0;
      width: 10px;
      height: 10px;
    }
  }
  @keyframes miniPulseMarker {
    0% {
      opacity: 1;
      width: 10px;
      height: 10px;
    }
    92% {
      opacity: 0;
      width: 20px;
      height: 20px;
    }
    100% {
      opacity: 0;
      width: 20px;
      height: 20px;
    }
  }
  // &.focused {
  //   background-color: #fff;
  //   animation: miniPulseMarker 1.5s ease infinite;
  //   width: 16px;
  //   height: 16px;
  //   opacity: 0.8;
  // }
  background-color: #fff;
  border-radius: 50%;
  animation: pulseMarker 1.7s ease infinite;
  cursor: pointer;
}
.page-header {
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: 50%;
}
.user-ytp-container {
  position: fixed;
  top: 10px;
  left: 10px;
  &.small {
    top: 15px;
    left: 15px;
  }
  &.center {
    left: 50%;
    transform: translateX(-50%);
  }
  z-index: 6;
  // @media only screen and (min-width: 1264px) {
  //   top: 50px;
  //   left: auto;
  //   right: 50px;
  //   transform: none;
  // }
}
.map-scroll-prompt {
  display: block;
  position: fixed;
  top: 83vh;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(13px) scale(1.1);
    }
    // 90% {
    //   transform: translateY(0);
    // }
    100% {
      transform: translateY(0);
    }
  }
  button {
    animation: jump 2s infinite;
  }
}
</style>
