export default {
  methods: {
    windowScale (out_min, out_max, dim) {
      const win = this.$vuetify.breakpoint
      let d
      if (dim === 'max') {
        d = Math.max(win.width, win.height)
      }
      if (dim === 'min') {
        d = Math.min(win.width, win.height)
      }
      if (!dim) {
        d = win.width
      }
      return (d - 320) * (out_max - out_min) / (1920 - 320) + out_min
    }
  }
}
