<template>
  <v-card
    class="mx-auto project-card"
    style="width: 100%;"
    elevation="0"
  >
    <a
      :href="isLegacy ? `${$store.state.oldAppUrl}/projects/${projectId}` : `/projects/${projectId}`"
      :onclick="!isLegacy ? 'return false;' : null"
      style="text-decoration: none;"
    >
      <v-img
        v-if="$vuetify.breakpoint.xsOnly"
        :src="image"
        class="mx-4"
        max-height="200"
        style="border-radius: 4px;"
      />
      <v-list-item
        three-line
        @click="viewProject"
      >
        <v-list-item-avatar
          v-if="$vuetify.breakpoint.smAndUp"
          tile
          width="250px"
          height="170px"
          color="grey"
          class="mr-6"
          style="border-radius: 4px;"
        >
          <v-img :src="image" />
        </v-list-item-avatar>
        <v-list-item-content class="align-start">
          <div
            class="d-flex"
            :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
          >
            <!-- <span class="overline">{{ type }} - {{ creationDate }}</span> -->
            <span class="overline">{{ type }}</span>
            <div
              class="d-flex align-center"
              :class="{ 'ml-auto': $vuetify.breakpoint.smAndUp }"
            >
              <YearsToPositiveDonut
                v-if="$vuetify.breakpoint.smAndUp"
                :project-type="type"
                :years-to-positive="yearsToPositive"
                :pending="yearsToPositive === null"
                :size="40"
              />
              <div
                class="caption shamrock--text"
                :class="{ 'ml-2': $vuetify.breakpoint.smAndUp }"
                style="line-height: 1.3;"
              >
                <span v-if="$vuetify.breakpoint.smAndUp">years to <br>positive</span>
                <span v-else-if="yearsToPositive !== null && yearsToPositive !== undefined">{{ yearsToPositive }} years to positive</span>
                <span v-else>Won't reach positive</span>
              </div>
            </div>
          </div>
          <v-list-item-title
            class="headline mb-1 d-block mb-auto"
            style="white-space: initial; flex-grow: 1;"
          >
            {{ title }}
            <v-tooltip
              v-if="isLegacy"
              top
            >
              <template #activator="{ on }">
                <v-chip
                  small
                  outlined
                  color="metal"
                  v-on="on"
                >
                  Legacy
                </v-chip>
              </template>
              This project was created using the legacy Pathfinder app.
            </v-tooltip>
          </v-list-item-title>
          <v-list-item-subtitle
            class="py-2 pr-3 d-block"
          >
            {{ description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </a>
  </v-card>
</template>

<script>
import YearsToPositiveDonut from '@/components/atoms/YearsToPositiveDonut'

export default {
  name: 'ProjectCard',
  components: {
    YearsToPositiveDonut
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Untitled'
    },
    creationDate: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    ytp: {
      type: Number,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    yearsToPositive: {
      type: Number,
      default: null
    },
    isLegacy: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    viewProject () {
      if (this.isLegacy) {
        // window.location.href = `http://app.climatepositivedesign.com/projects/${this.projectId}`
      } else {
        this.$router.push(`/projects/${this.projectId}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .project-card {
    cursor: pointer;
  }

</style>
